import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import ContactImage from "../../Assets/ContactImage.png";
import observeIntersection from "../../utils/intersectionObserver";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const contactSectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const schema = z.object({
    name: z
      .string()
      .min(4, { message: "The username must be 4 characters or more" }),
    phone: z.string(),
    message: z
      .string()
      .min(10, { message: "Must contain at least 10 characters." }),
  });

  type FormInput = z.infer<typeof schema>;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormInput>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: "",
      phone: "",
      message: "",
    },
  });

  useEffect(() => {
    observeIntersection({
      sectionRef: contactSectionRef,
      classNames: ["animate-slide-in-left-fast", "animate-slide-in-right-fast"],
      setterFunc: setIsVisible,
    });
  }, []);

  const handleFormSubmit = async (formData: FormInput) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://7ik9s2k445.execute-api.ap-south-1.amazonaws.com/sendemail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: formData.name,
            message: formData.message,
            phone: formData.phone,
            company_name: "Jaz builders",
            to: "jazbuilders.net@gmail.com",
            company_logo:
              "https://jaz-properties.s3.ap-south-1.amazonaws.com/zawarma/Jaz+New+Logo.png",
          }),
        }
      );

      if (response.status === 200) {
        toast.success("Email sent successfully");
      } else {
        toast.error("Failed to send email check the network");
      }
    } catch (error) {
      toast.error("Error sending email");
    } finally {
      setIsLoading(false);
      reset();
    }
  };

  return (
    <>
      <div
        id="contact"
        className={`max-w-screen-xxl mx-auto flex justify-evenly p-10 gap-20 overflow-hidden relative ${
          isVisible ? "opacity-100" : "opacity-0"
        }`}
        ref={contactSectionRef}
      >
        <div className="md:w-[60%] flex flex-col justify-center gap-4">
          <h2 className="text-3xl">Contact us</h2>
          <form
            onSubmit={handleSubmit(handleFormSubmit)}
            className=" flex flex-col min-w-[20rem] max-w-[50rem] gap-5 "
          >
            <label> Name</label>
            <input
              id="name"
              className="p-3 border border-Secondary rounded"
              {...register("name")}
            />
            {errors?.name?.message && (
              <p className="text-red-500">{errors.name.message}</p>
            )}
            <label> Your Phone</label>
            <input
              className="p-3 border border-Secondary rounded"
              id="email"
              {...register("phone")}
            />
            {errors?.phone?.message && (
              <p className="text-red-500">{errors.phone.message}</p>
            )}

            <label> Your Message</label>
            <textarea
              className="p-3 border border-Secondary rounded"
              id="message"
              {...register("message")}
            />
            {errors?.message?.message && (
              <p className="text-red-500">{errors.message.message}</p>
            )}
            <button className="p-3 bg-PrimaryButton text-white md:w-[34%]">
              {isLoading ? "Please wait..." : " Get's started"}
            </button>
          </form>
        </div>
        <div className="hidden sm:flex sm:items-center w-[25rem] h-[25rem] md:mt-10">
          <img src={ContactImage} alt="" />
        </div>
      </div>
    </>
  );
};

export default Contact;
