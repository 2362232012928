import React, { useEffect, useRef, useState } from "react";

import observeIntersection from "../../utils/intersectionObserver";

import GalleryPage from "../../components/Carousal/Carousal"
import ImageCard from "./data";
const Home = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const imgRef = useRef<HTMLImageElement | null>(null);
  useEffect(() => {
    const imgElement = imgRef.current;
    if (imgElement) {
      imgElement.onload = () => {
        observeIntersection({
          sectionRef: sectionRef,
          classNames: ["animate-slide-in-left", "animate-slide-in-right"],
          setterFunc: setIsVisible,
        });
      };
    }
  }, []);

  return (
    <div
      id="home"
      className={`flex flex-col w-screen  p-4
       `}
      ref={sectionRef}
    >
      <GalleryPage slides={ImageCard}/>
    </div>
  );
};

export default Home;
