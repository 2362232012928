import { React, useState, useEffect, useRef } from "react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import 'react-awesome-slider/dist/styles.css';
import Carousal from "./Carousal";
import homegalleryMainImage from "./components/HomeCompGalleryData/data";

function App() {
  const [isVisible, setIsVisible] = useState(false);

  return (
 <div className="flex flex-col p-4 px-10" id="gallery">

<h1 className="mb-4 text-2xl">Our Projects</h1> 
   <div>

<Carousal slides={homegalleryMainImage}/>
   </div>
 </div>
   
  );
}

export default App;
