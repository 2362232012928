 import ReplacePhoto from "../../../Assets/new_images/replace phot.jpg"
 import MKMReplce from "../../../Assets/new_images/MicrosoftTeams-image (88).jpg"
 import VTSR from "../../../Assets/new_images/VTSRnew.jpg"
 const Images = [

    {
      "folder_name": "construction",
      "files": [
        {
          "id": "EASY BUY (1)",
          "url": "https://jaz-properties.s3.amazonaws.com/vtsr/vtsr_mbl1.jpg"
        },
        
        {
          "id": "EASY BUY (1)",
          "url": "https://jaz-properties.s3.amazonaws.com/rosemahal/rosemahal_mbl.jpg"
        },
        
        // {
        //   "id": "EASY BUY (1)",
        //   "url": "https://jaz-properties.s3.ap-south-1.amazonaws.com/construction/IMG-20240216-WA0000+1.jpg"
        // },
        {
          "id": "GREEN PARK SCHOOL (2)",
          "url": "https://jaz-properties.s3.amazonaws.com/construction/GREEN PARK SCHOOL (2).jpg"
        },
        // {
        //   "id": "GREEN PARK SCHOOL (3)",
        //   "url": "https://jaz-properties.s3.amazonaws.com/construction/GREEN PARK SCHOOL (3).jpg"
        // },
        {
          "id": "GREEN PARK SCHOOL (5)",
          "url": "https://jaz-properties.s3.amazonaws.com/construction/GREEN PARK SCHOOL (5).jpg"
        },
        // {
        //   "id": "GREEN PARK SCHOOL (6)",
        //   "url": "https://jaz-properties.s3.amazonaws.com/construction/GREEN PARK SCHOOL (6).jpg"
        // },
        // {
        //   "id": "HB",
        //   "url": "https://jaz-properties.s3.amazonaws.com/construction/HB. CONST (1).jpeg"
        // },
        // {
        //   "id": "HB",
        //   "url": "https://jaz-properties.s3.amazonaws.com/construction/HB. CONST (2).jpeg"
        // },
        // {
        //   "id": "IT COMPLEX (3)",
        //   "url": "https://jaz-properties.s3.amazonaws.com/construction/IT COMPLEX (3).jpeg"
        // },
        // {
        //   "id": "MARKAS DRONE CAM (5)",
        //   "url": "https://jaz-properties.s3.amazonaws.com/construction/MARKAS DRONE CAM (5).JPG"
        // },
        //       {
        //   "id": "MARKAS DRONE CAM (4)",
        //   "url": "https://jaz-properties.s3.ap-south-1.amazonaws.com/construction/MARKAS+DRONE+CAM+(4).JPG"
        // },
        //       {
        //   "id": "MARKAS DRONE CAM (6)",
        //   "url": "https://jaz-properties.s3.ap-south-1.amazonaws.com/construction/MARKAS+DRONE+CAM+(6).JPG"
        // },
        // {
        //   "id": "MARKAS DRONE CAM (8)",
        //   "url": "https://jaz-properties.s3.amazonaws.com/construction/MARKAS DRONE CAM (8).JPG"
        // },
        // {
        //   "id": "MARKAS FOUNDATION (2)",
        //   "url": "https://jaz-properties.s3.amazonaws.com/construction/MARKAS FOUNDATION (2).jpg"
        // },
        // {
        //   "id": "MARKAS FOUNDATION (3)",
        //   "url": "https://jaz-properties.s3.amazonaws.com/construction/MARKAS FOUNDATION (3).jpg"
        // },
        {
          "id": "MKM",
          "url": MKMReplce
        },
        // {
        //   "id": "NADUPETTAI MOSQUE (1)",
        //   "url": "https://jaz-properties.s3.amazonaws.com/construction/NADUPETTAI MOSQUE (1).jpg"
        // },
        // {
        //   "id": "NADUPETTAI MOSQUE",
        //   "url": "https://jaz-properties.s3.amazonaws.com/construction/NADUPETTAI MOSQUE.jpeg"
        // },
        // {
        //   "id": "SPM (1)",
        //   "url": "https://jaz-properties.s3.amazonaws.com/construction/SPM (1).jpg"
        // },
        {
          "id": "SPM (2)",
          "url": "https://jaz-properties.s3.amazonaws.com/construction/SPM (2).jpg"
        },
        // {
        //   "id": "TMC",
        //   "url": "https://jaz-properties.s3.amazonaws.com/construction/TMC.jpg"
        // },
        {
          "id": "VALLAM",
          "url": ReplacePhoto
        },
        {
          "id": "VTSR SILKS (4)",
          "url": "https://jaz-properties.s3.amazonaws.com/construction/VTSR SILKS (4).jpeg"
        },
        // {
        //   "id": "VTSR SILKS",
        //   "url": "https://jaz-properties.s3.amazonaws.com/construction/VTSR SILKS.jpeg"
        // },
        // {
        //   "id": "VTSR SILKS",
        //   "url": VTSR
        // }
      ]
    },
    {
      "folder_name": "interior design",
      "files": [
        {
          "id": "JAZ OFFICE (1)",
          "url": "https://jaz-properties.s3.amazonaws.com/interior design/JAZ OFFICE (1).jpeg"
        },
        {
          "id": "JAZ OFFICE (3)",
          "url": "https://jaz-properties.s3.amazonaws.com/interior design/JAZ OFFICE (3).jpeg"
        },
        {
          "id": "JAZ OFFICE (4)",
          "url": "https://jaz-properties.s3.amazonaws.com/interior design/JAZ OFFICE (4).jpeg"
        },
        {
          "id": "VTSR SILKS (1)",
          "url": "https://jaz-properties.s3.amazonaws.com/interior design/VTSR SILKS (1).jpeg"
        },
        {
          "id": "VTSR SILKS (2)",
          "url": "https://jaz-properties.s3.amazonaws.com/interior design/VTSR SILKS (2).jpeg"
        },
        {
          "id": "VTSR SILKS (6)",
          "url": "https://jaz-properties.s3.amazonaws.com/interior design/VTSR SILKS (6).jpeg"
        },
        {
          "id": "ZWARMA SHOP",
          "url": "https://jaz-properties.s3.amazonaws.com/interior design/ZWARMA SHOP.png"
        },
      ]
    },
    {
      "folder_name": "real estate",
      "files": [
        {
          "id": "SPM (1)",
          "url": "https://jaz-properties.s3.amazonaws.com/real estate/SPM (1).jpeg"
        },
        {
          "id": "SPM (2)",
          "url": "https://jaz-properties.s3.amazonaws.com/real estate/SPM (2).jpeg"
        },
        {
          "id": "realestate1",
          "url": "https://jaz-properties.s3.amazonaws.com/real estate/realestate1.png"
        },
        // {
        //   "id": "realestate10",
        //   "url": "https://jaz-properties.s3.amazonaws.com/real estate/realestate10.jpeg"
        // },
        // {
        //   "id": "realestate11",
        //   "url": "https://jaz-properties.s3.amazonaws.com/real estate/realestate11.jpg"
        // },
        // {
        //   "id": "realestate2",
        //   "url": "https://jaz-properties.s3.amazonaws.com/real estate/realestate2.png"
        // },
        {
          "id": "realestate3",
          "url": "https://jaz-properties.s3.amazonaws.com/real estate/realestate3.png"
        },
        {
          "id": "realestate4",
          "url": "https://jaz-properties.s3.amazonaws.com/real estate/realestate4.png"
        },
        {
          "id": "realestate5",
          "url": "https://jaz-properties.s3.amazonaws.com/real estate/realestate5.png"
        },
        {
          "id": "realestate6",
          "url": "https://jaz-properties.s3.amazonaws.com/real estate/realestate6.png"
        },
        // {
        //   "id": "realestate7",
        //   "url": "https://jaz-properties.s3.amazonaws.com/real estate/realestate7.png"
        // },
        // {
        //   "id": "realestate8",
        //   "url": "https://jaz-properties.s3.amazonaws.com/real estate/realestate8.jpg"
        // },
        // {
        //   "id": "realestate9",
        //   "url": "https://jaz-properties.s3.amazonaws.com/real estate/realestate9.jpeg"
        // },
        {
          "id": "walkthrough 1",
          "url": "https://jaz-properties.s3.amazonaws.com/real estate/walkthrough 1.mp4"
        }
      ]
    }
  ]

export default Images