import React, { useState, useEffect } from "react";
import Image from "./OurWorksData";
import { Oval } from "react-loader-spinner";
const RealEstate = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (Image.length > 0) {
      setIsLoading(false);
    }
  }, [Image.length]);
  useEffect(() => {
    if (Image.length > 0) {
      setIsLoading(false);
    }
  }, [Image.length]);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {isLoading && (
        <div className="absolute top-[50%] left-[50%]">
          <Oval
            height={40}
            width={40}
            color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
      <div className="grid justify-items-center grid-cols-2 sm:grid-cols-3 gap-5 p-10 ">
        {Image.map(
          ({ folder_name, files }, index) =>
            folder_name === "construction" &&
            files.map(({ url }, fileIndex) => {
              console.log(url);
              const lastDotIndex = url.lastIndexOf("."); // Find the last occurrence of "."
              if (lastDotIndex !== -1) {
                const fileExtension = url.slice(lastDotIndex + 1); // Get the file extension

                console.log(fileExtension);
                return (
                  <React.Fragment key={`${index}-${fileIndex}`}>
                    {fileExtension.match(/(jpg|jpeg|png|gif)$/i) ? (
                      <img
                        src={url}
                        alt="description"
                        className="w-[250px] h-[180px] sm:w-[430px] sm:h-[430px] object-cover"
                      />
                    ) : fileExtension.match(/(mp4|webm|ogg)$/i) ? (
                      <video
                        src={url}
                        className="w-[250px] h-[200px] sm:w-[430px] sm:h-[430px]  object-cover"
                        controls
                      />
                    ) : null}
                  </React.Fragment>
                );
              } else {
                // Handle the case where there's no file extension
                console.log("Error occured");
                return null;
              }
            })
        )}
      </div>
    </div>
  );
};

export default RealEstate;
