import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import Home from "../Pages/Home/Home";
import Service from "../Pages/Service/Service";
import Gallery from "../Pages/Gallery/components/GalleryPage/GalleryPage";
import Layout from "../layout/Applayout/Applayout";
import LandingPage from "../Pages/LandingPage/LandingPage";
import RealEstate from "../Pages/Service/components/RealEstate";
import Construction from "../Pages/Service/components/Construction";
import InteriorDesign from "../Pages/Service/components/InteriorDesign";

const route = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<LandingPage />} />

          <Route path="service">
            <Route index element={<Service />} />
            <Route path="RealEstate" element={<RealEstate />} />
            <Route path="Construction" element={<Construction />} />
            <Route path="InteriorDesign" element={<InteriorDesign />} />
          </Route>

          <Route path="gallery/:imageId" element={<Gallery />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default route;
