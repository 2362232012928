import React from "react";
import { Link } from "react-router-dom";
import vector from "../../Assets/vector.png";
import { BiRightArrowAlt } from "react-icons/bi";
const AboutUs = () => {
  return (
    <div className="px-10 flex flex-col sm:gap-5 border">
      <h1 className="md:mb-4 text-2xl ">COMPANY PROFILE</h1>
      <div class="gap-10 flex h-full  md:h-auto flex-col p-5  md:flex-row   overflow-hidden scroll-mt-9">
        <ul class="list-disc space-y-4 md:pl-10 md:w-3/4">
          <li class="mb-2">
            Holistic Approach to Development: Integrating nature, aesthetics,
            technology, and sustainability into harmonious spaces.
          </li>
          <li class="mb-2">
            Diverse Portfolio, Unified Quality: Excellence and innovation across
            varied projects, from condominiums to commercial complexes.
          </li>
          <li class="mb-2">
            Client-Centric Innovation: 'YOU ON PRIORITY' service ethos ensures
            client satisfaction and lasting relationships.
          </li>
        </ul>

        <div className="flex flex-col gap-5 lg:ite justify-center md:w-1/2">
          <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-6xl  font-bold text-grey-custom">
            <span className="text-black-custom  ">Building Dreams,</span>
            <br></br>
            Crafting Excellence
          </h1>
          <a
            href="#contact"
            className="flex items-center w-40 justify-center gap-1 bg-PrimaryButton rounded group p-2 text-white"
          >
            <button
              className="flex items-center w-40 justify-center  gap-1 bg-PrimaryButton rounded group p-2 text-white "
              type="button"
            >
              Start with us
              <span className="group-hover:rotate-90 duration-300">
                <BiRightArrowAlt />
              </span>
            </button>
          </a>
        </div>
      </div>
      <div>
        <h1 className="mb-4 text-2xl">ABOUT US </h1>
        <p className="mb-8">
          More than a decade of glorious experience in creating projects
          recognized as masterpieces in the Tenkasi, South Tamilnadu{" "}
          <b>
            Mr. Sultan Syed Ibrahim founded JAZ BUILDERS & PROMOTERS in 2008{" "}
          </b>{" "}
          with a clear vision to transform the way people perceive quality.
          Today, JAZ BUILDERS & PROMOTERS is one of the most trusted brands in
          Tenkasi.
        </p>
        <p className="mb-8">
          Since its inception, the Company has continuously worked to achieve
          benchmark quality, a customer-centric approach, robust engineering,
          in-house research, unwavering business ethics, timeless values, and
          transparency in all areas of business conduct, all of which have
          helped to make it a popular real estate brand in Tenkasi.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
