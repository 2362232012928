import React from "react";
import Home from "../Home/Home";
import Service from "../Service/Service";
import Project from "../Projects/Project";
import Details from "../Details/Details";
import Gallery from "../Gallery/Gallery";
import Testimonial from "../Testimonials/Testimonial";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import AboutUs from "../AboutUs/AboutUs";

const LandingPage = () => {
  return (
    <div className="flex flex-col gap-4 ">
      <Home />
      <Service/>
      <AboutUs />
      {/* <Project /> */}
      {/* <Details /> */}
      <div>

      <Gallery />
      </div>
      <Testimonial />
     
      <Contact />
      <Footer />
    </div>
  );
};

export default LandingPage;
