import React from "react";
import "./App.css";
import RouterComp from "./Routes/route";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <div className="App overflow-hidden m-0 p-0">
      <RouterComp />
    </div>
  );
}

export default App;
