import Ram from "../../../Assets/new_images/RamaNew.jpg"
import VTSR from "../../../Assets/new_images/VTSRnew.jpg"
import NewImage1 from "../../../Assets/new_images/3d-rendering-house-model.jpg"
import NewImage2 from "../../../Assets/new_images/3d-rendering-house-model (1).jpg"
import NewImage3 from "../../../Assets/new_images/image.jpg"
import TMC from "../../../Assets/new_images/rosemahal_mbls.jpg"
import SBMBuilders from "../../../Assets/new_images/MicrosoftTeams-image (88).jpg"
import SMB from "../../../Assets/new_images/rosemahal_mblssds.jpg"
const testimonialCard = [
  {
    name: "VTSR Silks",
    location: "Tenkasi",
    comments:
      "Working with JAZ Builders has been amazing. They're really honest and trustworthy. They not only did a great job for us but also were open and reliable throughout the process.",

    image: VTSR,
    star: 3,
  },

  {
    name: "Ramachandra textiles",
    location: "Vanchinagar & Kerala",
    comments:
      "As a small business owner, I needed a trustworthy construction team for my textile business. JAZ Builders delivered beyond our expectations. The project was completed on schedule, and the quality of their work is evident in our beautiful new space. Grateful for their professionalism and expertise.",

    image: Ram,
    star: 4,
  },
  {
    name: "SPM Builders",
    location: "Tenkasi",
    comments:
      "Working with JAZ Builders has really improved the value of our properties. They build things that last, stay within the budget, and get it done on time. If you want to make your properties better, they're the team to go with.",

    image: SMB,
    star: 4,
  },
  {
    name: "Siva Kumar",
    location: "Melagaram",
    comments:
      "To build my dream home i choose JAZ builders to fulfil my dream and they committed to the project and made me to trust their process till the end and they transformed my vision into reality with even more creativity.",

    image: SBMBuilders,
    star: 4,
  },
  {
    name: "Dr.Siva chandran",
    location: "Tenkasi",
    comments:
      "JAZ builders was also flexible and listened to our needs, making changes when we asked. We're really pleased with the improvements they've made, and we recommend them to other hospitals looking for a construction partner.",
    image: TMC,
    star: 4,
  },
];

export default testimonialCard;
