import React, { Fragment, useEffect, useRef, useState } from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

import { useNavigate } from 'react-router';

const SimpleSlider: React.FC<any> = ({slides}) => {
  const sliderRef = useRef<AwesomeSlider | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const goToNextSlide = () => {
    const nextIndex = (currentIndex + 1) % slides.length;
    setCurrentIndex(nextIndex);
  };

  const goToPreviousSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const nextIndex  = isFirstSlide ? slides.length - 1: currentIndex -1
    setCurrentIndex(nextIndex);
  };
  const goToNextSlides = () => {
    const isFirstSlide = currentIndex === slides.length-1;
    const nextIndex  = isFirstSlide ? 0 - 1: currentIndex  + 1
    setCurrentIndex(nextIndex);
  };
  useEffect(() => {
    const sliderInterval = setInterval(goToNextSlide, 4000);

    return () => {
      clearInterval(sliderInterval);
    };
  }, [currentIndex, slides.length]);
  const goToSlide = (index: number) => {
    setCurrentIndex(index);
  };
  return (
    <div className="w-full sm:w-[100vw] sm:h-[100vh] overflow-hidden lg:w-[60vw] lg:p-2 lg:h-[80vh] lg:gap-10 lg:overflow-hidden lg:m-auto relative">
    <div className="flex flex-col gap-4">
      <img
        src={slides[currentIndex]?.image}
        alt={`Slide ${currentIndex}`}
        className="object-contain w-full h-full transition ease-out duration-500 sm:w-[100vw] sm:h-[100vh]"
        loading='eager'
      />
    </div>
    
    {/* Navigation Dots */}
    <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-2">
      {slides.map((slide: any, slideIndex: any) => (
        
        <button
          key={slideIndex}
          onClick={() => goToSlide(slideIndex)}
          className={`w-4 h-4 bg-gray-500 rounded-full focus:outline-none ${
            currentIndex === slideIndex ? 'bg-white transition ease-out duration-200' : ''
          }`}
        ></button>
      ))}
    </div>
  </div>
  );
};

export default SimpleSlider;
