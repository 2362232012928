import { useEffect, useRef, useState } from "react";
import testimonial from "./components/testimonialCard";
import observeIntersection from "../../utils/intersectionObserver";
import { Rating } from "react-simple-star-rating";

import {
  BsArrowRight,
  BsArrowLeft,
  BsArrowLeftSquareFill,
  BsArrowRightSquareFill,
} from "react-icons/bs";
import { FaStar } from "react-icons/fa";
import testimonialCard from "./components/testimonialCard";

const Testimonial = () => {
  //This custom style will be applied to card of comments
  const customStyles = {
    borderTopRightRadius: "100px",
    borderBottomRightRadius: "1%",
    borderBottomLeftRadius: "100px",
    borderTopLeftRadius: "1% ",
  };
  const [isVisible, setIsVisible] = useState();
  const [isVisible1, setIsVisible1] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const testimonialSectionRef = useRef(null);
  const commentRef = useRef(null);

  const setWindowDimension = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setWindowDimension);
    return () => {
      window.removeEventListener("resize", setWindowDimension);
    };
  }, []);
  useEffect(() => {
    observeIntersection({
      sectionRef: commentRef,
      classNames: ["animate-slide-in-left", "animate-slide-in-up-fast"],
      setterFunc: setIsVisible,
    });
  }, []);
  useEffect(() => {
    observeIntersection({
      sectionRef: testimonialSectionRef,
      classNames: ["animate-slide-in-left", "animate-slide-in-down"],
      setterFunc: setIsVisible,
    });
  }, []);
  //   When prevIndex is 0, (0 + 1) % 5 equals 1. So, it moves to the next index, which is 1.
  // When prevIndex is 4, (4 + 1) % 5 equals 0. So, it loops back to the first index, which is 0.
  useEffect(() => {
    handleNextClick();
  }, []);

  const handleNextClick = () => {
    setIsVisible1(false); // Apply fade-out animation
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonial.length);
      setIsVisible1(true); // Apply fade-in animation
    }, 200); // Wait for 300 milliseconds for fade-out animation
  };

  const handlePreviousClick = () => {
    setIsVisible1(false); // Apply fade-out animation
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? testimonial.length - 1 : prevIndex - 1
      );
      setIsVisible1(true); // Apply fade-in animation
    }, 300); // Wait for 300 milliseconds for fade-out animation
  };

  const current = testimonial[currentIndex];
  return (
    <div className="max-w-screen-xxl mx-auto  overflow-hidden">
      <div className="flex  flex-col items-center justify-center gap-4 p-4">
        <h1 className=" text-4xl font-medium">Client Testimonial</h1>
        <p className="text-xl">Let’s see what our clients say about us</p>
      </div>
      {windowWidth > 828 ? (
        <section
          className={`max-w-screen-xxl mx-auto md:px-10 grid grid-cols-2  gap-20 ${
            isVisible ? "opacity-100" : "opacity-0"
          } `}
          ref={testimonialSectionRef}
        >
          <div
            style={customStyles}
            className={`border-2 bg-Primary min-w-[400px] max-h-[350px] min-h-[300px] m-[3rem] p-[4rem] relative bg-primary text-Tertiary ${
              isVisible1 ? "animate-slide-in-left-fast" : ""
            }`}
            ref={commentRef}
          >
            <p>{current.comments}</p>
            <img
              className={`border-4 border-tertiary object-cover rounded-full w-20 h-20 absolute right-[-3rem] top-[5rem]  ${
                isVisible1 ? "animate-slide-in-up-fast" : ""
              }`}
              src={current.image}
              alt=""
            />
          </div>

          <div className="flex flex-col items-center justify-end gap-4 bottom-32 p-8">
            <p
              className={`text-3xl relative left-8 bottom-16 ${
                isVisible1 ? "animate-slide-in-down" : ""
              }`}
            >
              <span className="text-8xl font-thin">
                {(currentIndex + 1).toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                })}
              </span>{" "}
              /{" "}
              {testimonial.length.toLocaleString("en-US", {
                minimumIntegerDigits: 2,
              })}
            </p>
            <div
              className={`${isVisible1 ? "animate-slide-in-right-fast" : ""}`}
            >
              <p className="text-2xl font-semibold">{current.name}</p>
              <p>{current.location}</p>
              <div className="flex">
                {[...Array(5)].map((star, index, array) => {
                  index += 1;
                  const first = index;
                  const second = current.star;
                  const isYellow = index <= current.star;
                  return (
                    <button type="button" key={index} className="p-1">
                      <span>
                        <FaStar
                          className={
                            isYellow ? "text-yellow-500 flex" : "text-gray-500 "
                          }
                          size={30}
                        />
                      </span>
                    </button>
                  );
                })}
              </div>
            </div>
            <div className="flex items-center justify-center gap-16">
              <BsArrowLeftSquareFill
                className="text-PrimaryButton cursor-pointer"
                onClick={handlePreviousClick}
                size={40}
              />
              <BsArrowRightSquareFill
                className="text-PrimaryButton cursor-pointer"
                onClick={handleNextClick}
                size={40}
              />
            </div>
          </div>
        </section>
      ) : (
        <section
          className="flex flex-col gap-8  p-8 bg-Primary"
          ref={testimonialSectionRef}
        >
          <div
            className="grid grid-cols-3 gap-4 grid-flow-row-dense "
            ref={commentRef}
          >
            <p className="flex gap-2  text-Tertiary text-2xl whitespace-nowrap -rotate-360">
              What People are saying?
              <span>
                <FaStar className="text-PrimaryButton" size={30} />
              </span>
            </p>
            <img className="w-[100%] col-span-3" src={current.image} alt="" />
          </div>

          <div>
            <p className="text-3xl text-Tertiary font-medium p-4">
              Exceptional Work Experience
            </p>
            <p className="text-gray-400">{current.comments}</p>
          </div>
          <div className="text-gray-100 sm:flex gap-3 items-center">
            <p className="text-gray-100">{current.name}</p>
            <p>{current.location}</p>
          </div>
          <div>
            <div>
              {[...Array(5)].map((star, index) => {
                index += 1;
                const isYellow = index <= current.star;
                return (
                  <button type="button" key={index} className="p-1">
                    <span>
                      <FaStar
                        className={
                          isYellow ? "text-yellow-500 flex" : "text-gray-500 "
                        }
                        size={30}
                      />
                    </span>
                  </button>
                );
              })}
            </div>
            <div className="flex gap-12 justify-end p-8">
              <BsArrowLeft
                className="text-gray-100"
                onClick={handleNextClick}
                size={40}
              />
              <BsArrowRight
                className="text-gray-100"
                onClick={handlePreviousClick}
                size={40}
              />
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default Testimonial;
