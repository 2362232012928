import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import jazLogo from "../../Assets/Jaz-NewLogo-Name.png";
import { Link } from "react-scroll";
import { useLocation, useNavigate } from "react-router-dom";

interface LinkItem {
  id: number;
  link: string;
}

const Navbar: React.FC = () => {
  const links: LinkItem[] = [
    {
      id: 1,
      link: "home",
    },
    {
      id: 2,
      link: "service",
    },
    {
      id: 3,
      link: "gallery",
    },
    {
      id: 4,
      link: "contact",
    },
  ];

  const [nav, setNav] = useState<boolean>(false);
  const [active, setActive] = useState(`#home`);
  const location = useLocation();
  const navigate = useNavigate();
  const isHomePage = location.pathname === "/";

  const toggleNav = () => {
    setNav(!nav);
  };
  return (
    <div className="flex justify-between items-center bg-white text-black px-4 h-20 fixed w-full z-10 shadow-2xl">
      <div>
      <h1 className="text-3xl flex items-center  text-gray-600" style={{fontFamily:"Goldman",cursor:"pointer"}} onClick={()=>navigate("/")}>
    <img className="h-28" src={jazLogo} alt="Logo" />
  <span>
  </span>
  {/* JAZ Builders & Promoters */}
</h1>

      </div>
      <ul className="hidden md:flex">
        {links.map(({ id, link }) => (
          <li
            key={id}
            className="px-4  text-gray-500 cursor-pointer capitalize hover:scale-105 duration-200"
          >
            <Link
              to={link}
              smooth={true}
              offset={-80}
              duration={500}
              activeClass="font-bold"
              // onClick={() => {
              //   if (link == "service") {
              //     navigate("/service");
              //   } else {
              //     navigate("/");
              //   }
              // }}
            >
              {link}
            </Link>
          </li>
        ))}
      </ul>

      <div
        onClick={() => setNav(!nav)}
        className="md:hidden cursor-pointer pr-4 z-10 text-gray-700"
      >
        {nav ? <FaTimes size={30} /> : <FaBars size={30} />}
      </div>
      {nav && (
        <ul className="flex flex-col text-white  absolute top-0 right-0 w-[300px]  h-screen bg-gradient-to-b from-black to-gray-800 ">
          {links.map(({ id, link }) => (
            <li
              key={id}
              className="px-4 py-6 capitalize cursor-pointer text-xl"
            >
              <Link
                to={link}
                smooth={true}
                offset={-80}
                duration={500}
                onClick={() => {
                  setNav(!nav);
                  // if (link == "service") {
                  //   navigate("/service");
                  // } else {
                  //   navigate("/");
                  // }
                }}
              >
                {link}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Navbar;
