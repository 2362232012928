import Civil from"../../Assets/civil Engineer.jpg"
import Image1 from "../../Assets/new_images/3d-house-model-with-modern-architecture.jpg"
import Image2 from "../../Assets/new_images/3d-house-model-with-pool.jpg"
import Image3 from "../../Assets/new_images/3d-rendering-white-wood-living-room-near-bedroom-upstair.jpg"
import Image4 from "../../Assets/new_images/house-1477041_1920.jpg"
import Image5 from "../../Assets/new_images/interior-2685521.jpg"
const ImageCard = [
   
    {
        image:Image1,
        id: 2,
        text:"hello"
      },
      {
        image: Image2,
        id: 1,
        text:"hello"
      }, 
      {
        image:Image3,
        id: 2,
        text:"hello"
      },
      {
        image:Image4,
        id: 2,
        text:"hello"
      },
      {
        image:Image5,
        id: 2,
        text:"hello"
      },
    
    
  ];
  
  export default ImageCard;