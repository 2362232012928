import React from "react";
import footer_logo from "../../Assets/jaz-logo-white.png";
import footer_map from "../../Assets/footer_map.png";
import phone from "../../Assets/phone-call.png";
import facebook from "../../Assets/logo_facebook.png";
import instagram from "../../Assets/logo_Instagram.png";
import twitter from "../../Assets/logo_twitter.png";
import { BiBorderRadius } from "react-icons/bi";
import { Link } from "react-scroll";
import { ToastContainer } from "react-toastify";
const Footer = () => {
  const dummy = [
    {
      name: "home",
    },

    { name: "gallery" },
    {
      name: "contact",
    },
  ];
  return (
    <div className="bg-Primary">
      <div className="flex flex-col sm:flex-row gap-8 sm:justify-between px-[5%] py-[2%]">
        <div className="text-Tertiary flex flex-col gap-5 ">
          <div className="flex items-center break-words gap-1">
            <div className="flex items-center">
              <img
                src={footer_logo}
                className="w-6"
                alt="Logo"
              />
            </div>
            <div className="">
              <p className="">JAZ Builders & Promoters</p>
            </div>
          </div>
          {/* <p className="flex gap-2 items-center break-words">
            <span>
              <img src={footer_logo} className="w-10 h-10" alt="" />
            </span>
            <span className="text-2xl"> JAZ Builders & Promoters</span>{" "}
          </p> */}

          <div className="flex flex-col gap-3">
            <p className="flex gap-2 items-center break-words">
              <span>
                <img src={footer_map} alt="" />
              </span>
              <div className="flex flex-col">
                <p className="ml-3">174/5-5, Railway Road,</p>
                <p className="ml-3">Tenkasi-627811</p>
              </div>
            </p>
            <p className="flex gap-2 items-center break-words">
              <span>
                <img src={phone} alt="" />
              </span>
              <div className="flex flex-col">
                <p className="ml-2">+91 94863 22351,</p>
                <p className="ml-2">+91 63742 15677</p>
              </div>
            </p>
          </div>
        </div>
        <div className=" hidden  sm:flex sm:flex-col  text-white gap-4 ]">
          <p className="flex justify-center">Quick Links</p>
          {dummy.map((item) => (
            <Link
              to={item.name}
              smooth={true}
              spy={true}
              duration={500}
              offset={-70}
              activeClass="active"
              className=""
            >
              {/* Assuming each 'item' has a unique 'key' property */}
              <p className="capitalize cursor-pointer hover:underline ">
                {item.name}
              </p>
            </Link>
          ))}
        </div>
        <div className="text-white hidden sm:flex sm:flex-col  gap-4">
          <p>Social</p>
          <div className="flex flex-col justify-center gap-5  ">
            <span className="flex gap-2 cursor-pointer">
              <img src={twitter} alt="" />
              <p className="hover:underline">
                <a href="https://twitter.com/jaz_builders">Twitter</a>
              </p>
            </span>
            <span className="flex gap-2 cursor-pointer">
              <img src={facebook} alt="" />
              <p className="hover:underline">
                <a href="https://www.facebook.com/profile.php?id=61555816784733&mibextid=LQQJ4d">
                  Facebook
                </a>
              </p>
            </span>
            <span className="flex gap-2 cursor-pointer">
              <img src={instagram} alt="" />
              <p className="hover:underline">
                <a
                  href="https://www.instagram.com/jaz_builders_promoters
/"
                >
                  Instagram
                </a>
              </p>
            </span>
          </div>
        </div>

        <div className=" flex items-center md:w-[500px] md:h-[200px]">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d246.31252994819545!2d77.30099396745909!3d8.972101399999993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b06839ce06063e1%3A0x3b2556493142bfb7!2sJaz%20builders!5e0!3m2!1sen!2sin!4v1693305526899!5m2!1sen!2sin"
            className="w-full h-full"
            style={{
              border: "3px solid #fff",
              borderRadius: "0.4rem",
              zIndex: 1,
            }}
            loading="lazy"
          ></iframe>
        </div>
      </div>
      <div className="text-Tertiary flex flex-col justify-center items-center px-6 pb-10">
        <p>Copyright &copy; 2023 Jaz Builders. </p>
        <p className="text-xs ">
          All rights reserved. All trademarks are property of their respective
          owners.
        </p>
      </div>
      <div className="absolute bottom-0 right-0">
        <ToastContainer
          toastClassName="flex items-center"
          bodyClassName="p-4"

          // style={{ backgroundImage: `url(https://jaz-properties.s3.ap-south-1.amazonaws.com/zawarma/jaz-logo.png)` backgroundSize: 'contain', backgroundRepeat: 'no-repeat', paddingLeft: '50px',width:"20%",height:"20%" }}
        />
      </div>
    </div>
  );
};

export default Footer;
