import React, { useState, useEffect } from "react";
import "react-awesome-slider/dist/styles.css";
import { useNavigate } from "react-router";
const ImageCarousel = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
const navigate = useNavigate()
  const sliderStyles = {
    height: "100vh",
    width: "100vw",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const sliderStyles1 = {
    height: "100vh",
    width: "100vw",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const goToNextSlide = () => {
    const nextIndex = (currentIndex + 1) % slides.length;
    setCurrentIndex(nextIndex);
  };

  useEffect(() => {
    const sliderInterval = setInterval(goToNextSlide, 4000);

    return () => {
      clearInterval(sliderInterval);
    };
  }, [currentIndex, slides.length]);

  return (
    
      <div className="sm:gap-1 lg:flex lg:w-full lg:h-[90vh] lg:p-2 lg:gap-10">
        <div className="lg:flex lg:flex-col lg:gap-4 lg:w-1/2 lg:h-full p-1">
            <img
              src={slides[currentIndex]?.images[0]?.image}
              alt={`Slide ${currentIndex}`}
              className="object-fill w-full h-full lg:h-2/4 p-3 transition ease-out duration-200"
              // onClick={()=>navigate(`gallery/${slides[currentIndex]?.images[0]?.project}`)}
            />
            <img
              src={slides[currentIndex]?.images[1]?.image}
              alt={`Slide ${currentIndex}`}
              className="object-cover lg:h-2/4 p-3 transition ease-out duration-200"
              // onClick={()=>navigate(`gallery/${slides[currentIndex]?.images[1]?.project}`)}
            />
        </div>
        <div className="lg:w-1/2">
          <img
            src={slides[currentIndex]?.images[2]?.image}
            alt={`Slide ${currentIndex}`}
            height={90}
            className="object-cover w-full h-full lg:h-[89vh] p-2 transition ease-out duration-200"
            // onClick={()=>navigate(`gallery/${slides[currentIndex]?.images[2]?.project}`)}
          />
        </div>
      </div>
 
  );
};

export default ImageCarousel;
