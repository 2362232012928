import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";
import GalleryImagedata from "../GalleryImage/data";
// import GalleryMobileData from "../GalleryImage/mobileData";
import gallerypageStyles from "./galleryPageStyles.module.css";
import Gallerymbl from "../../components/GalleryMobile/GalleryMobile";
import {
  ArrowLeft,
  ArrowLeftFade,
  ArrowRight,
  ArrowRightFade,
} from "../../../../Assets/index";
import {
  EffectCoverflow,
  Pagination,
  Navigation,
  EffectCards,
} from "swiper/modules";
const gps = gallerypageStyles;

const GalleryPage = () => {
  const params = useParams();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const setWindowDimension = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setWindowDimension);
    return () => {
      window.removeEventListener("resize", setWindowDimension);
    };
  }, []);
  const data = GalleryImagedata;

const project = params?.imageId?.toLocaleLowerCase();

{
  data?.map((data)=>{
    return(
      <>
      
      {console.log(data?.name.toString().split(' ').join('').toLowerCase())}
      </>
    )
  })
}
  const filter = data?.filter((filter: any) => filter?.name.toString().split(' ').join('').toLowerCase()=== project);

  const [slide, setSlide] = useState(0);
  const length = filter?.[0]?.images.length - 1;
  const previous = () => {
    setSlide(slide === 0 ? filter?.[0]?.images.length - 1 : slide - 1);
  };
  const forward = () => {
    setSlide(slide === filter?.[0]?.images.length - 1 ? 0 : slide + 1);
  };

  useEffect(() => {
    console.log(slide, length, "ecert");
  }, [slide]);

  return (
    <>
      <div className={gps.carousel_main}>
        <div className={gps.Carousel}>
          {filter.map((data: any, index: any) => {
            return (
              <div className="relative">
                <div>
                  {data?.images?.map((data: any, index: any) => {
                    return (
                      <div key={index}>
                        <img
                          key={project}
                          src={
                            windowWidth > 728 ? data?.image : data?.mobileImage
                          }
                          alt={data?.id}
                          className={
                            slide === index
                              ? `${gps.slide}`
                              : `${gps.slide_hidden}`
                          }
                        />
{/* over lay in the carousal ************/}
                        {/* <div
                          className={
                            slide === index
                              ? `${gps.image_description}`
                              : `${gps.image_description_content_hidden}`
                          }
                        >
                          <div className="text-white font-sans font-semibold text-xl">
                            {data?.title}
                          </div>
                          {data?.description}
                        </div> */}
                      </div>
                    );
                  })}
                </div>
                <div className={` ${gps.arrow}`}>
                  {slide === 0 ? (
                    <img src={ArrowLeft} />
                  ) : (
                    <img src={ArrowLeftFade} onClick={previous} />
                  )}
                  {slide === 0 || slide < length ? (
                    <img src={ArrowRight} onClick={forward} />
                  ) : (
                    <img src={ArrowRightFade} />
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="d flex gap-2 justify-center align-middle p-6 ">
          {filter.map((data: any, index: any) => {
            return (
              <>
                {data?.images?.map((data: any, index: any) => {
                  return (
                    <>
                      <div
                        className={`w-3 h-3 rounded-full bg-gray-300 shadow-md  border-black ${
                          slide === index ? `${gps.index_text}` : ""
                        }`}
                      >
                        {""}
                      </div>
                    </>
                  );
                })}
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default GalleryPage;
