import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Hamburger from "../../components/Hamburger/Hamburger";
import { Outlet } from "react-router";

const Applayout = () => {
  // const [isMobile, setIsMobile] = useState(window.innerWidth < 730);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth < 730);
  //   };

  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  return (
    <div>
      <div className="sm:sticky sm:top-0 z-50 sm:w-100vw sm:bg-white">
        <Navbar />
      </div>

      {/* <div className="sticky top- pt-0.5 cursor-pointer z-50 select-none">
        {isMobile ? <Hamburger /> : null}
      </div> */}
      <div className=" pt-16 -z-50 w-90vw">
        <Outlet />
      </div>
  
    </div>
  );
};

export default Applayout;
